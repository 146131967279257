import {useCallback, useEffect, useMemo, useState} from 'react';
import {useQueryPipelines} from '../../../../api';
import {useCurrentTeam, useMassAction} from '../../../../hooks';
import {BffFilterOptions, PipelineCommonResponse} from '../../../../types';
import {Button} from '../../../button';
import {Dropdown} from '../../../dropdown';
import {DropdownSearch} from '../../../dropdown-search';
import {SuggestionIcon} from '../../../suggestions-indicator';
import {EmptyPipelinesListInfo} from '../../empty-pipelines-list';
import {PipelineDropdownItem} from '../../pipeline-dropdown-item';

type MassAddToPipelineButtonProps = {
  filters: BffFilterOptions;
  totalCount: number;
  onSuccess?: () => void;
};

export const MassAddToPipelineButton = ({filters, totalCount, onSuccess}: MassAddToPipelineButtonProps) => {
  const {executeMassAction} = useMassAction('addSuggestion');
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const {id: teamId} = useCurrentTeam();

  const closeDropdown = useCallback(() => setIsOpen(false), []);

  const {data} = useQueryPipelines(teamId, {limit: 100, offset: 0}, {enabled: isOpen});
  const pipelines = useMemo(() => data?.items || [], [data]);

  const pipelinesListNotEmpty = pipelines.length > 0;

  const massAddToPipeline = useCallback(
    (pipeline: PipelineCommonResponse) => {
      closeDropdown();
      executeMassAction({
        targetId: pipeline.id,
        targetName: pipeline.title,
        filters,
        totalCount,
        onSuccess,
      });
    },
    [closeDropdown, executeMassAction, filters, onSuccess, totalCount]
  );

  const searchedPipelines = pipelines.filter(pipeline =>
    pipeline.pipeline.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    if (isOpen) {
      setSearchQuery('');
    }
  }, [isOpen]);

  return (
    <Dropdown
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      renderDropdownTrigger={() => (
        <Button size="sm" variant="tertiary" outline chevron="down" icon={<SuggestionIcon />}>
          Add to list
        </Button>
      )}
      dropdownHeader={
        <DropdownSearch
          placeholder="Search for a List"
          onChange={q => setSearchQuery(q)}
          visible={pipelines.length > 3}
        />
      }
    >
      {pipelinesListNotEmpty ? (
        <Dropdown.ItemsGroup
          items={searchedPipelines.map(({pipeline}) => ({
            label: pipeline.title,
            type: {
              name: 'custom',
              onClick: () => massAddToPipeline(pipeline),
              element: (
                <PipelineDropdownItem
                  pipelineName={pipeline.title}
                  checked={false}
                  isPrivate={pipeline.private}
                  noCheckbox
                />
              ),
            },
            dataIntercomTarget: 'add searched profiles to pipeline',
          }))}
        ></Dropdown.ItemsGroup>
      ) : (
        <EmptyPipelinesListInfo />
      )}
    </Dropdown>
  );
};
