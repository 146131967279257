import {Briefcase as BriefcaseIcon} from 'phosphor-react';
import {Control, Controller, FieldErrors} from 'react-hook-form';
import {AuthBox, AuthBoxIcon} from '../../../../../../../components/auth';
import {Button} from '../../../../../../../components/button';
import {Multiselect, RadioGroup, simpleOptionMapper} from '../../../../../../../components/form';
import {
  HowDidYouHearAnswer,
  InterestedInOurImplementationServicesAnswer,
  TeamMainUseCase,
} from '../../../../../../../types';
import {CreateTeamFormState} from '../ManuallyCreateTeam';

type Props = {
  control: Control<CreateTeamFormState, object>;
  errors: FieldErrors<CreateTeamFormState>;
  isSubmitting: boolean;
};

const teamMainUseCases = Object.values(TeamMainUseCase);
const mainUseCaseOptions = simpleOptionMapper(teamMainUseCases);
const howDidYouHearAnswers = Object.values(HowDidYouHearAnswer);
const howDidYouHearOptions = simpleOptionMapper(howDidYouHearAnswers);
const interestedInOurImplementationServicesAnswers = Object.values(
  InterestedInOurImplementationServicesAnswer
);
const interestedInOurImplementationServicesOptions = interestedInOurImplementationServicesAnswers.map(
  answer => ({
    label: answer,
    value: answer,
  })
);

export const Questionnaire = ({control, errors, isSubmitting}: Props) => {
  const labelClassNames = 'text-left font-semibold text-sm -mb-1 mt-4';

  return (
    <AuthBox headerTitle="Set Up Your Account" headerImage={<HeaderImage />}>
      <div className="text-left">
        <p className={labelClassNames}>What's your main use case?</p>
        <Controller
          control={control}
          name="mainUseCase"
          rules={{required: 'Please select an answer'}}
          render={({field: {onChange, value}}) => (
            <Multiselect
              singleOption
              options={mainUseCaseOptions}
              onChange={onChange}
              {...(value && {initialValues: [value]})}
              isInvalid={Boolean(errors.mainUseCase)}
              message={errors.mainUseCase?.message}
              placeholder="Select from the list"
            />
          )}
        />
      </div>
      <div className="text-left">
        <p className={labelClassNames}>
          Would you like to set up a call to learn about our{' '}
          <a className="underline" href="https://www.theswarm.com/api" target="_blank" rel="noreferrer">
            API
          </a>
          ?
        </p>
        <Controller
          control={control}
          name="interestedInOurImplementationServices"
          rules={{required: 'Please select an answer'}}
          render={({field: {onChange, value}}) => (
            <RadioGroup
              isStacked={true}
              name="interestedInOurImplementationServices"
              radioButtons={interestedInOurImplementationServicesOptions}
              message={errors.interestedInOurImplementationServices?.message}
              isInvalid={Boolean(errors.interestedInOurImplementationServices)}
              defaultValue={value}
              onValueChange={onChange}
              color="black"
            />
          )}
        />
      </div>
      <div className="text-left">
        <p className={labelClassNames}>How did you hear about The Swarm?</p>
        <Controller
          control={control}
          name="howDidYouHear"
          rules={{required: 'Please select an answer'}}
          render={({field: {onChange, value}}) => (
            <Multiselect
              singleOption
              options={howDidYouHearOptions}
              onChange={onChange}
              {...(value && {initialValues: [value]})}
              isInvalid={Boolean(errors.howDidYouHear)}
              message={errors.howDidYouHear?.message}
              placeholder="Select from the list"
            />
          )}
        />
      </div>
      <Button
        type="submit"
        variant="primary"
        fullWidth
        loading={isSubmitting}
        size="lg"
        className="mt-4"
        tracking={{
          label: 'finish',
          location: 'create team',
        }}
      >
        Finish
      </Button>
    </AuthBox>
  );
};

const HeaderImage = () => (
  <AuthBoxIcon>
    <BriefcaseIcon size={32} />
  </AuthBoxIcon>
);
