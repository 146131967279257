import {Dialog} from '@headlessui/react';
import {ShieldCheck as ShieldIcon} from 'phosphor-react';
import {segmentTrack} from '../../../utils';
import {Button} from '../../button';
import {Card} from '../../card';
import {AddWorkOverlapsButton} from '../overlaps/AddWorkOverlapsButton';
import {ImportMethodTile} from './ImportMethodTile';

type Props = {
  goWorkOverlaps: () => unknown;
  goImportManual: () => unknown;
  goChromeExtension: () => unknown;
  goImportGoogle: () => unknown;
};

const onSafetyCenterClick = () =>
  segmentTrack('Button Clicked', {label: 'safety center', location: 'connections import'});

export const ImportConnectionsIntro = ({
  goWorkOverlaps,
  goImportManual,
  goChromeExtension,
  goImportGoogle,
}: Props) => {
  return (
    <>
      <Dialog.Title
        as="h3"
        className="flex flex-row place-content-between text-3xl font-bold text-swarm-black"
      >
        Add your connections
      </Dialog.Title>

      <Dialog.Description className="mb-6 mt-4 text-base font-normal text-swarm-gray-700">
        Start by importing your connections. We'll combine and enrich them for you.
      </Dialog.Description>

      <ImportMethodTile
        icon={<img src="/icons/work-overlaps.svg" alt="Google Contacts" className="h-6" />}
        name="work overlaps"
        title="Work and education overlaps"
        subtitle="Current/former colleagues and school alumni"
        action={<AddWorkOverlapsButton goWorkOverlaps={goWorkOverlaps} />}
      />

      <ImportMethodTile
        icon={<img src="/icons/chrome-extension.svg" alt="Chrome Extension" className="h-8" />}
        name="chrome extension"
        title="Import LinkedIn connections"
        subtitle="automatically via Chrome Extension"
        action={
          <Button onClick={goChromeExtension} variant="primary" className="w-[140px]">
            Install
          </Button>
        }
      />

      <ImportMethodTile
        icon={<img src="/icons/csv.svg" alt="CSV import icon" className="h-6" />}
        name="linkedin csv"
        title="Import LinkedIn connections"
        subtitle="manually via CSV"
        action={
          <Button onClick={goImportManual} variant="secondary" className="w-[140px]">
            Import
          </Button>
        }
      />

      <ImportMethodTile
        icon={<img src="/icons/google.svg" alt="Google Contacts" className="h-6" />}
        name="google contacts and calendar"
        title="Import Google Contacts &amp; Calendar"
        action={
          <Button onClick={goImportGoogle} variant="primary" className="w-[140px]">
            Import
          </Button>
        }
      />

      <Card className="mt-4 bg-swarm-gray-50 p-3.5">
        <div className="flex flex-row items-center">
          <ShieldIcon size="26px" color="black" weight="fill" className="mr-4 shrink-0" />
          <p>
            Your data is safe, private, and secured with us.
            <br />
            To learn more, visit our{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://theswarm.com/trust"
              className="text-swarm-black underline"
              onClick={onSafetyCenterClick}
            >
              Trust Center
            </a>
            .
          </p>
        </div>
      </Card>
    </>
  );
};
