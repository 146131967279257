import {useQuery, UseQueryOptions} from 'react-query';
import {useCurrentTeam} from '../hooks';
import {
  BffAggregateRequest,
  BffAggregateResponse,
  BffProfilesBulkActionRequest,
  GetProfileResponse,
  GetProfilesListRequest,
  GetProfilesListResponse,
} from '../types';
import {isGetProfileResponse, isGetProfilesListResponse} from '../types/bffTypes.guard';
import {typeCheck} from '../utils';
import {apiClient} from './apiClient';

const getBffProfile = (id: string): Promise<GetProfileResponse> =>
  apiClient
    .get(`/profiles/${id}`, {headers: {'X-Swarm-Bff': 'true'}})
    .then(res => typeCheck(res.data, isGetProfileResponse));

export const useQueryBffProfile = (id: string, options?: UseQueryOptions<GetProfileResponse>) => {
  const {id: teamId} = useCurrentTeam();
  return useQuery<GetProfileResponse>(['bffProfile', teamId, id], () => getBffProfile(id), {
    refetchOnWindowFocus: false,
    ...options,
  });
};

export const getBffProfileList = (req: GetProfilesListRequest): Promise<GetProfilesListResponse> =>
  apiClient
    .post('/profiles', req, {headers: {'X-Swarm-Bff': 'true'}})
    .then(res => typeCheck(res.data, isGetProfilesListResponse));

export const useQueryBffProfilesList = (
  getProfilesInput: GetProfilesListRequest,
  options?: UseQueryOptions<GetProfilesListResponse>
) => {
  const {id: teamId} = useCurrentTeam();
  return useQuery<GetProfilesListResponse>(
    ['profilesList', teamId, getProfilesInput],
    () => getBffProfileList(getProfilesInput),
    options
  );
};

export const getBffProfileListAsCsv = (req: GetProfilesListRequest) =>
  apiClient.post('/profiles', req, {
    headers: {
      'X-Swarm-Bff': 'true',
      Accept: 'text/csv',
    },

    responseType: 'blob',
  });

const getProfileListBffAggregate = async (params: BffAggregateRequest): Promise<BffAggregateResponse> =>
  apiClient
    .post('/profiles/aggregate', params, {
      headers: {'X-Swarm-Bff': 'true'},
    })
    .then(res => res.data);

export const useQueryProfileListBffAggregate = (
  getProfileListInput: BffAggregateRequest,
  options: UseQueryOptions<BffAggregateResponse> = {}
) => {
  const {id: teamId} = useCurrentTeam();
  return useQuery<BffAggregateResponse>(
    ['profilesAggregate', teamId, getProfileListInput],
    () => getProfileListBffAggregate(getProfileListInput),
    options
  );
};

export const bffProfilesBulkAction = (req: BffProfilesBulkActionRequest) =>
  apiClient.post('/profiles/bulk', req, {headers: {'X-Swarm-Bff': 'true'}});
