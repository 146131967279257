export type DeniedPermissionReason = 'role' | 'plan' | 'not-found';

export enum Permission {
  HasTeamAccess = 'HasTeamAccess',
  TeamMember = 'TeamMember',
  TeamMemberUpdate = 'TeamMemberUpdate',
  Company = 'Company',
  TeamPipelineUpdate = 'TeamPipelineUpdate',
  Pipeline = 'Pipeline',
  PlanUpdate = 'PlanUpdate',
  TeamSettingsUpdate = 'TeamSettingsUpdate',
  ConnectionStrength = 'ConnectionStrength',
  ProfileContactDataRead = 'ProfileContactDataRead',
  TeamNoteDelete = 'TeamNoteDelete',
  Tag = 'Tag',
  TagAssign = 'TagAssign',
  TagUpdate = 'TagUpdate',
  TeamTagUpdate = 'TeamTagUpdate',
  CRM = 'CRM',
  CRMIntegration = 'CRMIntegration',
  CRMExport = 'CRMExport',
  ShowGettingStartedVideo = 'ShowGettingStartedVideo',
  OutOfNetworkScope = 'OutOfNetworkScope',
  AiNetworkMapper = 'AiNetworkMapper',
  APIKeyRead = 'APIKeyRead',
  APIKeyWrite = 'APIKeyWrite',
}

export type ActionPermission = {
  permissionName: Permission;
  featureName: string;
  location?: UpgradeTooltipContentLocation;
};

export type UpgradeTooltipContentLocation =
  | 'crm filter'
  | 'connection score'
  | 'connection score filter'
  | 'connection company filters'
  | 'team settings tabs'
  | 'tags filter'
  | 'profile contact data'
  | 'profile connection score'
  | 'network scope switcher';
