import {useEffect, useState} from 'react';
import {AuthBox} from '../../../../../../components/auth';
import {Button} from '../../../../../../components/button';
import {sleep} from '../../../../../../utils';

type Props = {
  onFinish: () => void;
};

export const Ready = ({onFinish}: Props) => {
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const enableButton = () => {
    sleep(5000).then(() => {
      setButtonDisabled(false);
    });
  };

  useEffect(() => {
    enableButton();
  }, []);

  return (
    <AuthBox flexible>
      <iframe
        width="640"
        height="360"
        src="https://www.loom.com/embed/e29b198a92d643eda5f33f7a82d0f5ca?sid=20be5d81-913e-44bb-ae8f-d862017f7dc7"
        allowFullScreen
        className="-mt-5"
      ></iframe>
      <h2 className="mt-4 text-3xl font-semibold">The Swarm in one minute</h2>
      <p className="mb-4 text-swarm-gray-700">Watch this video while we finalize your account...</p>
      <div className="flex justify-center">
        <Button
          variant="primary"
          onClick={onFinish}
          disableTitleCase
          loading={buttonDisabled}
          size="lg"
          tracking={{label: 'go to dashboard', location: 'magical onboarding finalization'}}
        >
          {buttonDisabled ? 'Getting things ready...' : 'Go to dashboard'}
        </Button>
      </div>
    </AuthBox>
  );
};
