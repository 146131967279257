import {AuthBox} from '../../../../../../components/auth';
import {Button} from '../../../../../../components/button';

type Props = {
  onSkip: () => void;
  onConfirm: () => void;
};

export const AIMapperEnable = ({onSkip, onConfirm}: Props) => {
  return (
    <AuthBox headerImage={<img className="h-[283px] w-full" src="/images/ai-network-mapper.png" />}>
      <h2 className="py-2 text-3xl font-bold" data-intercom-target="ai network mapper header">
        AI Network Mapper
      </h2>
      <p className="p-4 text-swarm-gray-700">
        The Swarm adds your colleagues as "Connectors" on your account and maps their connections based on
        their work experience to jumpstart your company network. They won't be notified.
      </p>
      <div className="flex flex-col gap-2">
        <Button
          fullWidth
          variant="primary"
          onClick={onConfirm}
          size="lg"
          tracking={{label: 'enable', location: 'company mapping'}}
        >
          Enable
        </Button>
        <Button
          fullWidth
          onClick={onSkip}
          disableTitleCase
          size="lg"
          tracking={{label: 'skip for now', location: 'company mapping'}}
        >
          Skip for now
        </Button>
      </div>
    </AuthBox>
  );
};
