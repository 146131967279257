import {useCallback, useMemo, useState} from 'react';
import {FiTag as TagIcon} from 'react-icons/fi';
import {TbTagsOff as UnassignTagIcon} from 'react-icons/tb';
import {useQueryTagsSimple} from '../../../api';
import {useMassAction} from '../../../hooks';
import {BffFilterOptions, TagSimple} from '../../../types';
import {sleep} from '../../../utils';
import {LoadingSpinner} from '../../animations';
import {Button} from '../../button';
import {Dropdown} from '../../dropdown';
import {DropdownSearch} from '../../dropdown-search';
import {Tag} from '../tag';
import {EmptyTagsListInfo} from '../tags-list-dropdown';
import Styles from '../tags-list-dropdown/TagsListDropdown.module.scss';

type MassTagsProps = {
  filters: BffFilterOptions;
  totalCount: number;
  onSuccess?: () => void;
  actionType: 'addTag' | 'removeTag';
  actionTarget: 'companies' | 'profiles';
};

export const MassTagsAction = ({filters, totalCount, onSuccess, actionType, actionTarget}: MassTagsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const {executeMassAction} = useMassAction(actionType, actionTarget);

  const {data, isLoading: isTagsListLoading} = useQueryTagsSimple({
    enabled: isOpen,
  });

  const tags = useMemo(() => data?.items || [], [data?.items]);
  const filteredTags = tags.filter(tag => tag.name.toLowerCase().includes(searchQuery.toLowerCase()));

  const handleAction = useCallback(
    async (tag: TagSimple) => {
      setIsOpen(false);
      await executeMassAction({
        targetId: tag.id,
        targetName: tag.name,
        totalCount,
        filters,
        onSuccess,
      }).then(() => {
        sleep(2000).then(() => {
          onSuccess?.();
        });
      });
    },
    [executeMassAction, filters, onSuccess, totalCount]
  );

  const button =
    actionType === 'removeTag' ? (
      <Button variant="tertiary" size="sm" outline chevron="down" icon={<UnassignTagIcon color="#ED4D37" />}>
        <span className="text-swarm-red">Unasign tag</span>
      </Button>
    ) : (
      <Button variant="tertiary" size="sm" outline chevron="down" icon={<TagIcon />}>
        Assign tag
      </Button>
    );

  return (
    <Dropdown
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      renderDropdownTrigger={() => button}
      dropdownHeader={
        !isTagsListLoading && (
          <DropdownSearch
            query=""
            placeholder="Search for a tag"
            onChange={q => setSearchQuery(q)}
            visible={tags.length > 3}
          />
        )
      }
    >
      {isTagsListLoading && (
        <div className={Styles.loadingStateWrapper}>
          <LoadingSpinner size="small" color="black" />
        </div>
      )}
      {!isTagsListLoading &&
        filteredTags.map(tag => {
          return (
            <Dropdown.Item
              key={tag.id}
              label={tag.name}
              type={{
                name: 'custom',
                onClick: () => handleAction(tag),
                element: (
                  <div className={Styles.item}>
                    <Tag content={tag.name} color={tag.color} />
                  </div>
                ),
              }}
            />
          );
        })}
      {!isTagsListLoading && !filteredTags.length && <EmptyTagsListInfo />}
    </Dropdown>
  );
};
