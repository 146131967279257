import {useCallback} from 'react';
import {bffCompaniesBulkAction, bffProfilesBulkAction} from '../api';
import {notify} from '../components/notifications';
import {BffFilterOptions, MassCompaniesActionType, MassProfilesActionType} from '../types';
import {useConfirm} from './useConfirm';

type MassActionOptions = {
  targetId: string;
  targetName: string;
  filters: BffFilterOptions;
  totalCount: number;
  onSuccess?: () => void;
};

type ActionType = MassProfilesActionType | MassCompaniesActionType;

const confirmExpression: Record<ActionType, (targetName: string, detailedCount: string) => string> = {
  addSuggestion: (targetName, detailedCount) =>
    `Are you sure you want to add ${detailedCount} to ${targetName}?`,
  removeFromPipeline: (targetName, detailedCount) =>
    `Are you sure you want to remove ${detailedCount} from ${targetName}?`,
  addTag: (targetName, detailedCount) =>
    `Are you sure you want to assign ${targetName} tag to ${detailedCount}?`,
  removeTag: (targetName, detailedCount) =>
    `Are you sure you want to remove ${targetName} tag from ${detailedCount}?`,
};

const generateConfirmMessage = (targetName: string, detailedCount: string, actionType: ActionType) =>
  confirmExpression[actionType](targetName, detailedCount);

export const useMassAction = (actionType: ActionType, actionTarget = 'profiles') => {
  const {isConfirmed} = useConfirm();

  const executeMassAction = useCallback(
    async (options: MassActionOptions) => {
      const {targetId, targetName, filters, totalCount, onSuccess} = options;

      const detailedCount = `${totalCount.toLocaleString('en-US')} ${actionTarget}`;

      const isConfirmedAction = await isConfirmed(
        generateConfirmMessage(targetName, detailedCount, actionType),
        actionType.includes('remove') ? 'removal' : 'info'
      );
      if (!isConfirmedAction) return;

      if (actionTarget === 'companies') {
        await bffCompaniesBulkAction({
          filters,
          actionType: actionType as MassCompaniesActionType,
          targetId,
        });
      } else {
        await bffProfilesBulkAction({filters, actionType, targetId});
      }
      onSuccess?.();
      notify(`${detailedCount} are being processed for ${targetName}. It may take a while.`);
    },
    [actionTarget, actionType, isConfirmed]
  );

  return {executeMassAction};
};
