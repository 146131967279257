import {useCallback, useEffect, useMemo, useState} from 'react';
import {BulkCrmExportMenu} from '../../components/crm-export-menu';
import {PremiumFeatureClickWrapper} from '../../components/premium-feature-wrapper';
import {Table} from '../../components/table';
import {BulkCompaniesTags, MassTagsAssign, MassTagsUnassign} from '../../components/tags';
import {BffCompanyListRow, BffFilterOptions, Permission} from '../../types';
import {pluralizedCompany} from '../../utils';
import {type UseCompaniesTableResult} from './useCompaniesTable';

type Props = {
  tableData: UseCompaniesTableResult;
  filters: BffFilterOptions;
  totalCount: number;
  onChangeSuccess: () => void;
};

export const CompaniesListTable = ({tableData, filters, onChangeSuccess, totalCount}: Props) => {
  const [massActionsMode, setMassActionsMode] = useState(false);

  const selectedRows = tableData.getSelectedRowModel().rows;
  const selectedCompanies = useMemo(() => selectedRows.map(row => row.original), [selectedRows]);
  const selectedCompaniesIds = useMemo(
    () => selectedCompanies.map(company => company.id),
    [selectedCompanies]
  );
  const selectedCompaniesCount = selectedCompanies.length;

  const shouldShowBulkActions = selectedCompaniesCount > 0;
  const shouldShowMassActionsSwitch = tableData.getIsAllRowsSelected() && selectedCompaniesCount < totalCount;

  const unselectAllRows = useCallback(() => tableData.toggleAllRowsSelected(false), [tableData]);

  const onBulkActionSuccess = useCallback(() => {
    onChangeSuccess();
    unselectAllRows();
  }, [onChangeSuccess, unselectAllRows]);

  const activateMassActions = useCallback(() => setMassActionsMode(true), []);

  useEffect(() => {
    if (!shouldShowMassActionsSwitch) {
      setMassActionsMode(false);
    }
  }, [shouldShowMassActionsSwitch]);

  const selectedRowsInfo = useMemo(
    () => `${selectedCompaniesCount} ${pluralizedCompany(selectedCompaniesCount)} selected`,
    [selectedCompaniesCount]
  );

  return (
    <Table<BffCompanyListRow>
      headerGroups={tableData.getHeaderGroups()}
      rowModel={tableData.getRowModel()}
      totalWidth={tableData.getTotalSize()}
      rowKeyPath="original.id"
      bulkActionsSlot={
        massActionsMode ? (
          <>
            <MassTagsAssign
              filters={filters}
              totalCount={totalCount}
              onSuccess={onBulkActionSuccess}
              actionTarget="companies"
            />
            <MassTagsUnassign
              filters={filters}
              totalCount={totalCount}
              onSuccess={onBulkActionSuccess}
              actionTarget="companies"
            />
          </>
        ) : (
          <>
            <BulkCompaniesTags
              selectedCompanies={selectedCompanies}
              onAssignSuccess={onBulkActionSuccess}
              onUnassignSuccess={onBulkActionSuccess}
              disabled={!shouldShowBulkActions}
            />
            <PremiumFeatureClickWrapper permission={Permission.CRMExport}>
              <BulkCrmExportMenu
                targetIds={selectedCompaniesIds}
                targetType="company"
                onSuccess={onChangeSuccess}
                disabled={!shouldShowBulkActions}
              />
            </PremiumFeatureClickWrapper>
            {shouldShowBulkActions && <span>{selectedRowsInfo}</span>}
          </>
        )
      }
      massActionsSwitchData={
        shouldShowMassActionsSwitch
          ? {
              massActionsMode,
              activateMassActions,
              unselectAllRows,
              selectedRowsCount: selectedCompaniesCount,
              totalCount,
            }
          : undefined
      }
    />
  );
};
