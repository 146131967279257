import {Helmet} from 'react-helmet';
import {PageHeader} from '../../components/page-header';
import {TabLinks} from '../../components/tabs';
import {AppLayout} from '../../layouts';
import {Api} from './api';
import {useTabsConfig} from './useTabsConfig';

export const ApiPage = () => {
  const tabs = useTabsConfig();

  return (
    <>
      <Helmet>
        <title>API - The Swarm</title>
      </Helmet>
      <AppLayout
        header={<PageHeader title="Team Settings" />}
        subHeader={<TabLinks tabs={tabs} activeTab="API" />}
      >
        <Api />
      </AppLayout>
    </>
  );
};
