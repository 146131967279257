import {ReactNode} from 'react';

type Props = {
  children: ReactNode;
  heading?: string;
  headingIcon?: ReactNode;
  subheading?: string;
};

export const TabPageContent = ({children, heading, headingIcon, subheading}: Props) => (
  <div className="mx-auto max-w-4xl">
    <div className="relative my-4 rounded-lg border border-swarm-gray-200 p-4 shadow-md">
      {(heading || subheading) && (
        <div className="-mx-4 -mt-4 mb-4 flex flex-col gap-1 border-b border-swarm-gray-200 p-4">
          {heading && (
            <h2 className="flex items-center gap-2 text-xl font-bold">
              {headingIcon}
              {heading}
            </h2>
          )}
          {subheading && <p className="text-swarm-gray-700">{subheading}</p>}
        </div>
      )}
      {children}
    </div>
  </div>
);
