import {DotsThreeVertical} from 'phosphor-react';
import {useCallback, useState} from 'react';
import {FiAlertOctagon as AlertIcon} from 'react-icons/fi';
import {PiCopy as DuplicateIcon} from 'react-icons/pi';
import {useSwarmAnalytics} from '../../hooks';
import {Button} from '../button';
import {Dropdown} from '../dropdown';

type Props = {
  target: 'profile' | 'company';
};

export const ReportDotsMenu = ({target}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const closeDropdown = useCallback(() => setIsOpen(false), []);
  const {reportInaccurateData, reportDuplicateProfile} = useSwarmAnalytics();
  const isCompany = target === 'company';

  return (
    <Dropdown
      renderDropdownTrigger={() => (
        <Button
          intercomTarget="single-profile-dots-menu"
          variant="tertiary"
          icon={<DotsThreeVertical weight="bold" size={22} />}
          iconOnly
        ></Button>
      )}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      placement="bottom-start"
    >
      {!isCompany && (
        <Dropdown.Item
          label="Report inaccurate data"
          icon={<AlertIcon />}
          type={{
            name: 'button',
            onClick: () => reportInaccurateData(closeDropdown),
          }}
          dataIntercomTarget="report-inaccurate-data"
        />
      )}
      <Dropdown.Item
        label={`Report duplicate${isCompany ? ' company' : ''} profile`}
        icon={<DuplicateIcon />}
        type={{
          name: 'button',
          onClick: () => reportDuplicateProfile(closeDropdown),
        }}
        dataIntercomTarget={`report-duplicate-${isCompany ? 'company-' : ''}profile`}
      />
    </Dropdown>
  );
};
