import {ColumnDef} from '@tanstack/react-table';
import {useMemo} from 'react';
import {Link} from 'react-router-dom';
import {Avatar} from '../../components/avatar';
import {LinkedinProfileLink} from '../../components/linkedin-profile-link';
import {ProfileLink} from '../../components/profile-link';
import {Table, TableInnerHeader, useSwarmTable, useTablePagination} from '../../components/table';
import {TruncateWithTooltip} from '../../components/truncate-with-tooltip';
import {useCurrentUser} from '../../hooks';
import {Role} from '../../types';
import {getConfig} from '../../utils';
import {MemberOptionsDropdown} from './components';
import {RolesMap} from './constants';
import Styles from './Members.module.scss';
import {MembersListItem, MembersListItemStatus} from './types';

type Props = {
  members: MembersListItem[];
};

const {memberConnectionsEnabled} = getConfig();

export const JoinedMembersList = ({members}: Props) => {
  const {id: currentUserId} = useCurrentUser();

  const activeMembers = members.filter(member => {
    return member.status === MembersListItemStatus.Active && member.role !== Role.Connector;
  });

  const pagination = useTablePagination();
  const columns = useMemo<ColumnDef<MembersListItem>[]>(
    () => [
      {
        accessorKey: 'name',
        header: () => <TableInnerHeader label="Name" />,
        cell: info => {
          const {name, profileId, linkedInUrl} = info.row.original;
          return (
            <div className={Styles.nameCellWrapper}>
              <Avatar fullname={name} size="md" />
              {profileId ? (
                <ProfileLink profileId={profileId} profileName={name} />
              ) : (
                <span className={Styles.nameWrapper}>
                  <TruncateWithTooltip content={name}>{name}</TruncateWithTooltip>
                </span>
              )}
              {linkedInUrl && <LinkedinProfileLink linkedinUrl={linkedInUrl} />}
            </div>
          );
        },
        size: 250,
      },
      {
        accessorKey: 'connectionCount',
        header: () => <TableInnerHeader label="Connections" />,
        cell: info => {
          const {userId, connectionCount} = info.row.original;
          const isCurrentUser = userId === currentUserId;
          const connectionCountString = (connectionCount || 0).toLocaleString('en-US');

          return memberConnectionsEnabled ? (
            <Link to={isCurrentUser ? '/my-connections' : `/members/${info.row.original.id}`}>
              {connectionCountString}
            </Link>
          ) : (
            connectionCountString
          );
        },
        size: 120,
        meta: {
          truncate: true,
        },
      },
      {
        accessorKey: 'email',
        header: () => <TableInnerHeader label="E-mail" />,
        cell: info => {
          const {email, role} = info.row.original;

          if (role === Role.Connector) {
            return '';
          }

          return email;
        },
        size: 250,
        meta: {
          truncate: true,
        },
      },
      {
        accessorKey: 'role',
        header: () => <TableInnerHeader label="Role" />,
        cell: ({row}) => (
          <span data-intercom-target={`role ${RolesMap[row.original.role]}`}>
            {RolesMap[row.original.role]}
          </span>
        ),
        size: 140,
        meta: {
          truncate: true,
        },
      },
      {
        accessorKey: 'actions',
        header: () => <TableInnerHeader label="Actions" hideLabel />,
        cell: info => {
          const member = info.row.original;

          return <MemberOptionsDropdown member={member} />;
        },
        size: 60,
        enableResizing: false,
      },
    ],
    [currentUserId]
  );

  const table = useSwarmTable<MembersListItem>({
    uniqueName: 'activeMembers',
    rows: activeMembers,
    columns,
    defaultColumnSize: 250,
    defaultColumnMinSize: 50,
    isLoaded: true,
    pagination,
  });

  return (
    <Table
      headerGroups={table.getHeaderGroups()}
      rowModel={table.getRowModel()}
      totalWidth={table.getTotalSize()}
      cellHeight={60}
      rowClassName={({status}) =>
        status === MembersListItemStatus.Invited ? 'text-swarm-gray-700 grayscale' : 'text-swarm-black'
      }
    />
  );
};
