import {BffFilterOptions} from '../../../types';
import {MassTagsAction} from './MassTagsAction';

type MassTagsAssignProps = {
  filters: BffFilterOptions;
  totalCount: number;
  onSuccess?: () => void;
  actionTarget: 'companies' | 'profiles';
};

export const MassTagsUnassign = ({filters, totalCount, onSuccess, actionTarget}: MassTagsAssignProps) => (
  <MassTagsAction
    filters={filters}
    totalCount={totalCount}
    onSuccess={onSuccess}
    actionType="removeTag"
    actionTarget={actionTarget}
  />
);
