import {AddressBook as ConnectionsIcon, DotsThreeVertical, Trash} from 'phosphor-react';
import {ReactElement, useCallback, useState} from 'react';
import {FiUser as UserIcon} from 'react-icons/fi';
import {PiUserCircle as ProfileIcon} from 'react-icons/pi';
import {useNavigate} from 'react-router-dom';
import {Button} from '../../../components/button';
import {Dropdown} from '../../../components/dropdown';
import {PaidRoleBadge} from '../../../components/paid-role-badge';
import {useCheckPermission, useConfirm, useCurrentUser, useTeamMembers} from '../../../hooks';
import {Permission, Role} from '../../../types';
import {segmentTrack} from '../../../utils';
import {MembersListItem, MembersListItemStatus} from '../types';

type Props = {
  member: MembersListItem;
};
export const MemberOptionsDropdown = ({member}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const {isConfirmed} = useConfirm();
  const {onUpdateMemberRole, onDeleteMember} = useTeamMembers();
  const {id: currentUserId} = useCurrentUser();
  const {id: memberId, userId, profileId, status, role} = member;
  const navigate = useNavigate();

  const isCurrentUser = userId === currentUserId;
  const canTeamMemberUpdate = useCheckPermission(Permission.TeamMemberUpdate) && !isCurrentUser;
  const isActive = status === MembersListItemStatus.Active;
  const isOwner = role === Role.Owner;
  const shouldDisplayChangeRoleButton = canTeamMemberUpdate && isActive;

  const closeDropdown = useCallback(() => setIsOpen(false), []);

  const changeRole = useCallback(
    (newRole: Role) => onUpdateMemberRole(member, newRole),
    [onUpdateMemberRole, member]
  );

  const goToMemberConnections = useCallback(
    () => navigate({pathname: isCurrentUser ? '/my-connections' : `/members/${memberId}`}),
    [navigate, isCurrentUser, memberId]
  );

  const goToProfile = useCallback(
    () => (profileId ? navigate({pathname: `/people/${profileId}`}) : null),
    [navigate, profileId]
  );

  const changeRoleToAdminButton = (
    <Dropdown.Item
      key="make-admin"
      label={
        <>
          Make an Admin <PaidRoleBadge role={Role.Admin} />
        </>
      }
      type={{
        name: 'button',
        onClick: () => {
          changeRole(Role.Admin);
          segmentTrack('Selector Applied', {
            label: 'make an admin',
          });
          closeDropdown();
        },
      }}
      icon={<UserIcon />}
    />
  );

  const changeRoleToContributorButton = (
    <Dropdown.Item
      key="make-contributor"
      label={
        <>
          Make a Contributor <PaidRoleBadge role={Role.Contributor} />
        </>
      }
      type={{
        name: 'button',
        onClick: () => {
          changeRole(Role.Contributor);
          segmentTrack('Selector Applied', {
            label: 'make a contributor',
          });
          closeDropdown();
        },
      }}
      icon={<UserIcon />}
    />
  );

  const changeRoleToGuestButton = (
    <Dropdown.Item
      key="make-guest"
      label={
        <>
          Make a Guest <PaidRoleBadge role={Role.Guest} />
        </>
      }
      type={{
        name: 'button',
        onClick: () => {
          changeRole(Role.Guest);
          segmentTrack('Selector Applied', {
            label: 'make a guest',
          });
          closeDropdown();
        },
      }}
      icon={<UserIcon />}
    />
  );

  const rolesToButtonsMap: Record<Role, ReactElement[]> = {
    [Role.Owner]: [],
    [Role.Admin]: [changeRoleToContributorButton, changeRoleToGuestButton],
    [Role.Contributor]: [changeRoleToAdminButton, changeRoleToGuestButton],
    [Role.Guest]: [changeRoleToAdminButton, changeRoleToContributorButton],
    [Role.Connector]: [],
  };

  const changeRoleButtons = shouldDisplayChangeRoleButton ? rolesToButtonsMap[role] : null;

  // Remove button
  const shouldDisplayRemoveMemberButton = canTeamMemberUpdate && isActive && !isOwner;
  const deleteMember = useCallback(
    () => onDeleteMember(member).then(() => setIsOpen(false)),
    [onDeleteMember, member]
  );

  const removeMemberButton = shouldDisplayRemoveMemberButton ? (
    <Dropdown.Item
      label="Remove"
      type={{
        name: 'button',
        onClick: async () => {
          closeDropdown();
          const agreed = await isConfirmed(
            'Are you sure you want to remove this member from your team? Their connections will be removed.',
            'removal'
          );
          if (agreed) {
            deleteMember();
          }
        },
        variant: 'danger',
      }}
      icon={<Trash weight="bold" />}
      tracking={{
        label: 'remove',
        location: 'remove member modal',
      }}
    />
  ) : null;

  const viewProfileButton = profileId ? (
    <Dropdown.Item
      label="View profile"
      type={{
        name: 'button',
        onClick: goToProfile,
      }}
      icon={<ProfileIcon />}
    />
  ) : null;

  const membersConnectionsButton = (
    <Dropdown.Item
      label={isCurrentUser ? 'My connections' : "Member's connections"}
      type={{
        name: 'button',
        onClick: goToMemberConnections,
      }}
      icon={<ConnectionsIcon />}
    />
  );

  return (
    <Dropdown
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      renderDropdownTrigger={() => (
        <Button
          iconOnly
          icon={<DotsThreeVertical weight="bold" size={20} />}
          onClick={() => setIsOpen(open => !open)}
          variant="tertiary"
        />
      )}
      placement="bottom-end"
    >
      {viewProfileButton}
      {membersConnectionsButton}
      {changeRoleButtons}
      {removeMemberButton}
    </Dropdown>
  );
};
