import {ColumnDef} from '@tanstack/react-table';
import {useCallback, useMemo} from 'react';
import {Link} from 'react-router-dom';
import {Avatar} from '../../components/avatar';
import {Button} from '../../components/button';
import {LinkedinProfileLink} from '../../components/linkedin-profile-link';
import {NoResults} from '../../components/no-results';
import {Pill} from '../../components/pill';
import {ProfileLink} from '../../components/profile-link';
import {Table, TableInnerHeader, useSwarmTable, useTablePagination} from '../../components/table';
import {TruncateWithTooltip} from '../../components/truncate-with-tooltip';
import {useCheckPermission, useConfirm, useTeamMembers} from '../../hooks';
import {Permission, Role} from '../../types';
import {getConfig} from '../../utils';
import {AddMembersButton} from './components';
import Styles from './Members.module.scss';
import {MembersListItem, MembersListItemStatus} from './types';

type Props = {
  refetch: () => void;
  members: MembersListItem[];
};

const {memberConnectionsEnabled} = getConfig();

export const ConnectorsMembersList = ({members, refetch}: Props) => {
  const connectors = members
    .filter(member => member.role === Role.Connector)
    .sort((a, b) => (b.connectionCount || 0) - (a.connectionCount || 0));

  const shouldDisplayOptions = useCheckPermission(Permission.TeamMemberUpdate);

  const pagination = useTablePagination();

  const columns = useMemo<ColumnDef<MembersListItem>[]>(
    () => [
      {
        accessorKey: 'name',
        header: () => <TableInnerHeader label="Name" />,
        cell: info => {
          const {name, status, profileId, linkedInUrl} = info.row.original;
          return (
            <div className={Styles.nameCellWrapper}>
              <Avatar fullname={name} size="md" outline empty />
              {profileId ? (
                <ProfileLink profileId={profileId} profileName={name} />
              ) : (
                <span className={Styles.nameWrapper}>
                  <TruncateWithTooltip content={name}>{name}</TruncateWithTooltip>
                </span>
              )}
              {linkedInUrl && <LinkedinProfileLink linkedinUrl={linkedInUrl} />}
              {status === MembersListItemStatus.Invited && <Pill color="gray">Invited</Pill>}
            </div>
          );
        },
        size: 250,
      },
      {
        accessorKey: 'connectionCount',
        header: () => <TableInnerHeader label="Connections" />,
        cell: info => {
          const connectionCount = (info.row.original.connectionCount || 0).toLocaleString('en-US');
          const isZeroConnections = connectionCount === '0';
          const content = (
            <span {...(isZeroConnections ? {'data-intercom-target': '0 connections'} : {})}>
              {connectionCount}
            </span>
          );
          return memberConnectionsEnabled ? (
            <Link to={`/members/${info.row.original.id}`}>{content}</Link>
          ) : (
            content
          );
        },
        size: 120,
        meta: {
          truncate: true,
        },
      },
      {
        accessorKey: 'role',
        header: () => <TableInnerHeader label="Role" />,
        cell: () => <span data-intercom-target="role Connector">Connector</span>,
        size: 140,
        meta: {
          truncate: true,
        },
      },
      ...(shouldDisplayOptions
        ? ([
            {
              accessorKey: '',
              header: () => <TableInnerHeader label="Actions" />,
              cell: ({row}) => <ConnectorsActions connector={row.original} />,
              size: 200,
              enableResizing: false,
              id: 'actions',
            },
          ] satisfies ColumnDef<MembersListItem>[])
        : []),
    ],
    [shouldDisplayOptions]
  );

  const table = useSwarmTable<MembersListItem>({
    uniqueName: 'connectorsMembers',
    rows: connectors,
    columns,
    defaultColumnSize: 250,
    defaultColumnMinSize: 50,
    isLoaded: true,
    pagination,
  });

  if (!connectors.length) {
    return (
      <NoResults page="pipeline" heading="No connectors." subheading="Add members to grow your Swarm.">
        <AddMembersButton onSuccess={refetch} label="Add Connectors" initialRole={Role.Connector} />
      </NoResults>
    );
  }

  return (
    <Table
      headerGroups={table.getHeaderGroups()}
      rowModel={table.getRowModel()}
      totalWidth={table.getTotalSize()}
      cellHeight={60}
    />
  );
};

type ConnectorActionsProps = {
  connector: MembersListItem;
};

const ConnectorsActions = ({connector}: ConnectorActionsProps) => {
  const {onDeleteMember} = useTeamMembers();
  const {isConfirmed} = useConfirm();

  const deleteConnector = useCallback(async () => {
    const confirmDelete = await isConfirmed('Are you sure you want to remove this Connector?', 'removal');
    if (confirmDelete) {
      onDeleteMember(connector);
    }
  }, [connector, isConfirmed, onDeleteMember]);

  return (
    <div className="gap-2">
      <Button size="xs" variant="tertiary" outline onClick={deleteConnector} tracking={{label: 'Remove'}}>
        Remove
      </Button>
    </div>
  );
};
