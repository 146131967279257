import {Dialog} from '@headlessui/react';
import Rive from '@rive-app/react-canvas';
import {useCallback} from 'react';
import {useForm} from 'react-hook-form';
import {useIntercom} from 'react-use-intercom';
import {useMutationUpdateUserLinkedinURL} from '../../../api/user';
import {useCurrentUser, useShouldOpenImportModal} from '../../../hooks';
import {Button} from '../../button';
import {Input} from '../../form';
import {trackStartImportClick} from '../analytics.utils';

type WorkOverlapsFormState = {
  linkedInURL: string;
};

type Props = {
  onClose: () => void;
  onSuccess: () => void;
};

const ERROR_MESSAGE = 'Please enter a valid LinkedIn URL';

export const ImportConnectionsWorkOverlapsForm = ({onClose, onSuccess}: Props) => {
  const {turnOffShowingModal} = useShouldOpenImportModal();
  const {showArticle} = useIntercom();
  const {
    formState: {isSubmitting, errors},
    register,
    handleSubmit,
    setError,
  } = useForm<WorkOverlapsFormState>();

  const {id: userId} = useCurrentUser();
  const mutationUpdateUserLinkedinURL = useMutationUpdateUserLinkedinURL(userId);

  const onSubmit = useCallback(
    (formState: WorkOverlapsFormState) =>
      mutationUpdateUserLinkedinURL.mutate(
        {
          linkedinUrl: formState.linkedInURL,
        },
        {
          onSuccess,
          onError: () => setError('linkedInURL', {message: ERROR_MESSAGE}),
        }
      ),
    [mutationUpdateUserLinkedinURL, onSuccess, setError]
  );

  const onClickReadMore = useCallback(() => {
    showArticle(7919256);
  }, [showArticle]);

  const onCancelButtonClick = useCallback(() => {
    onClose();
    turnOffShowingModal();
  }, [onClose, turnOffShowingModal]);

  return (
    <>
      <Dialog.Title as="h3" className="text-3xl font-bold text-swarm-black">
        Work and education overlaps
      </Dialog.Title>

      <Dialog.Description className="mb-4 mt-2 font-normal">
        We’ll automatically find people you may know based on your experience.
      </Dialog.Description>

      <Rive
        src="/animations/workoverlaps.riv"
        className="h-[390px] w-full rounded border border-swarm-gray-400"
      />

      <Dialog.Description className="mb-4 mt-2 font-normal">
        This step only adds your overlaps.{' '}
        <a href="#" onClick={onClickReadMore} className="text-base text-swarm-black underline">
          Read more.
        </a>
        <br />
        You can import your Linkedin network via our Chrome extension or CSV import.
      </Dialog.Description>

      <form className="pt-6" onSubmit={handleSubmit(onSubmit)}>
        <fieldset className="rounded bg-swarm-gray-100 p-4">
          <span className="font-medium text-swarm-black">
            Paste in your{' '}
            <a href="https://www.linkedin.com/in/" target="_blank" rel="noreferrer" className="underline">
              LinkedIn profile URL
            </a>
          </span>
          <Input
            {...register('linkedInURL')}
            type="text"
            placeholder="https://www.linkedin.com/in/yourname/"
            isInvalid={Boolean(errors.linkedInURL)}
            message={errors.linkedInURL?.message}
          />
        </fieldset>

        <div className="mt-8 flex justify-between">
          <Button
            onClick={onCancelButtonClick}
            type="reset"
            tracking={{label: 'I don’t have LinkedIn', location: 'work overlaps form'}}
          >
            I don't have LinkedIn
          </Button>

          <Button
            variant="primary"
            type="submit"
            loading={isSubmitting}
            onClick={() => trackStartImportClick('overlaps')}
          >
            Continue
          </Button>
        </div>
      </form>
    </>
  );
};
