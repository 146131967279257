import {useState} from 'react';
import {AuthBox} from '../../../../../../components/auth';
import {Avatar} from '../../../../../../components/avatar';
import {Button} from '../../../../../../components/button';
import {addHttpsToUrl} from '../../../../../../utils';

type Props = {
  onAcceptCompany: () => void;
  onDeclineCompany: () => void;
  company: {
    name: string;
    logoURL: string;
    url: string;
  };
};

export const CompanyAutoMatched = ({onAcceptCompany, onDeclineCompany, company}: Props) => {
  const [showPlaceholder, setShowPlaceholder] = useState(false);

  return (
    <AuthBox headerTitle="Verify Your Company">
      <h3 className="text-left">Is this Your Current Company?</h3>
      <div className="mb-2 mt-1 flex items-center gap-4 rounded border border-swarm-gray-500 p-2">
        {!showPlaceholder ? (
          <img
            src={company.logoURL || ''} // empty string is a fix for the case when logoURL is undefined and some browsers cut off src attr
            alt={company.name}
            className="size-6"
            onError={() => setShowPlaceholder(true)}
          />
        ) : (
          <Avatar fullname={company.name} size="md" />
        )}
        <div className="text-left leading-4">
          <h3 className="">{company.name}</h3>
          <a
            href={addHttpsToUrl(company.url)}
            target="_blank"
            rel="noreferrer noopener"
            className="text-xs text-swarm-gray-700"
          >
            {company.url}
          </a>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <Button
          fullWidth
          variant="primary"
          onClick={onAcceptCompany}
          size="lg"
          tracking={{label: 'confirm', location: 'company mapping'}}
        >
          Confirm
        </Button>
        <Button
          fullWidth
          onClick={onDeclineCompany}
          disableTitleCase
          size="lg"
          tracking={{label: 'switch company', location: 'company mapping'}}
        >
          Switch company
        </Button>
      </div>
    </AuthBox>
  );
};
