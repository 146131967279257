import {useEffect} from 'react';
import {FiChevronLeft as BackIcon} from 'react-icons/fi';
import {useNavigate, useParams} from 'react-router-dom';
import {useQueryBffProfile} from '../../api';
import {LoadingSpinner} from '../../components/animations';
import {Button} from '../../components/button';
import {CrmExportMenu} from '../../components/crm-export-menu';
import {NoResults} from '../../components/no-results';
import {PageHeader} from '../../components/page-header';
import {PremiumFeatureClickWrapper} from '../../components/premium-feature-wrapper';
import {ReportDotsMenu} from '../../components/report-dots-menu';
import {useConditionallyGoBack} from '../../hooks';
import {AppLayout} from '../../layouts';
import {Permission} from '../../types';
import {getConfig} from '../../utils';
import {SingleProfilePageContent} from './SingleProfilePageContent';

const {crmProfileExportEnabled} = getConfig();

export const SingleProfilePage = () => {
  const {id = ''} = useParams();
  const {data: profile, isLoading, refetch} = useQueryBffProfile(id);

  const goBack = useConditionallyGoBack({defaultPath: '/my-connections'});
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && !profile) {
      navigate('/network-graph');
    }
  }, [navigate, isLoading, profile]);

  return (
    <AppLayout
      header={
        <PageHeader
          hasBottomSeparator
          rightSlot={
            <>
              {crmProfileExportEnabled && (
                <PremiumFeatureClickWrapper permission={Permission.CRMExport}>
                  <CrmExportMenu targetIds={[id]} targetType="profile" onSuccess={() => refetch()} />
                </PremiumFeatureClickWrapper>
              )}
              <ReportDotsMenu target="profile" />
            </>
          }
        >
          <Button onClick={goBack} icon={<BackIcon />} variant="tertiary" iconOnly>
            Go back
          </Button>
        </PageHeader>
      }
    >
      {profile ? (
        <SingleProfilePageContent profile={profile} refetchData={refetch} />
      ) : isLoading ? (
        <LoadingSpinner size="small" color="black" centered />
      ) : (
        <NoResults
          heading="Profile does not exist."
          subheading="Provided link might be broken or Profile is not accessible."
          page="error"
        />
      )}
    </AppLayout>
  );
};
