import {flexRender, HeaderGroup} from '@tanstack/react-table';
import cs from 'classnames';
import {ReactNode, useMemo} from 'react';
import {FiChevronDown, FiChevronUp} from 'react-icons/fi';
import {useUiSetting} from '../../hooks';
import Styles from './TableHeader.module.scss';
import {ColumnSizeRecord} from './types';
import {getColumnWidthsSettingsKey} from './utils';

type Props<TItem> = {
  headerGroups: HeaderGroup<TItem>[];
  bulkActionsSlot?: ReactNode;
  massActionsSwitchData?: {
    massActionsMode: boolean;
    activateMassActions: () => void;
    unselectAllRows: () => void;
    selectedRowsCount: number;
    totalCount: number;
  };
  height?: string;
};

export const TableHeader = <TItem extends object>({
  headerGroups,
  bulkActionsSlot,
  massActionsSwitchData,
  height,
}: Props<TItem>) => {
  const columnWidthsSettingsKey = useMemo(() => getColumnWidthsSettingsKey(window.location.pathname), []);

  const initialSettingsWidths = headerGroups[0].headers.map(header => {
    return {
      id: header.id,
      width: header.getSize(),
    };
  });
  const [, setHeadersWidths] = useUiSetting<ColumnSizeRecord[]>(columnWidthsSettingsKey);

  const saveSize = (id: string, width: number) => {
    const newHeadersWidths = initialSettingsWidths.map(header => {
      if (header.id === id) {
        return {
          id,
          width,
        };
      }
      return header;
    });
    setHeadersWidths(newHeadersWidths);
  };

  const massActionsVisible = !!massActionsSwitchData;

  return (
    <div className={Styles.stickyHeader}>
      {headerGroups.map(headerGroup => (
        <div
          key={headerGroup.id}
          className={cs(Styles.tableHeader, {
            [Styles.tableHeaderMassActionsVisible]: massActionsVisible,
            [Styles.tableHeaderWithBulkActions]: bulkActionsSlot,
          })}
          style={{height}}
        >
          {bulkActionsSlot && <div className={Styles.bulkActions}>{bulkActionsSlot}</div>}
          <div className={Styles.tableHeadersWrapper}>
            {headerGroup.headers.map(header => {
              return (
                <div
                  key={header.id}
                  className={cs(Styles.tableTh)}
                  style={{
                    left: header.getStart(),
                    width: header.getSize(),
                    height,
                  }}
                >
                  {header.isPlaceholder ? null : (
                    <div
                      className={cs(Styles.tableThContent, {
                        [Styles.tableThSortable]: header.column.getCanSort(),
                      })}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {header.column.getCanSort()
                        ? {
                            asc: <FiChevronUp />,
                            desc: <FiChevronDown />,
                          }[header.column.getIsSorted() as string]
                        : null}
                    </div>
                  )}
                  {header.column.getCanResize() && (
                    <div
                      onMouseDown={header.getResizeHandler()}
                      onTouchStart={header.getResizeHandler()}
                      onMouseUp={() => saveSize(header.id, header.getSize())}
                      onTouchEnd={() => saveSize(header.id, header.getSize())}
                      className={Styles.resizer}
                    ></div>
                  )}
                </div>
              );
            })}
          </div>
          {massActionsVisible && (
            <MassActionsSwitch
              massActionsMode={massActionsSwitchData.massActionsMode}
              activateMassActions={massActionsSwitchData.activateMassActions}
              unselectAllRows={massActionsSwitchData.unselectAllRows}
              selectedRowsCount={massActionsSwitchData.selectedRowsCount}
              totalCount={massActionsSwitchData.totalCount}
            />
          )}
        </div>
      ))}
    </div>
  );
};

type MassActionsProps = {
  massActionsMode: boolean;
  activateMassActions: () => void;
  unselectAllRows: () => void;
  selectedRowsCount: number;
  totalCount: number;
};

const MassActionsSwitch = ({
  massActionsMode,
  activateMassActions,
  unselectAllRows,
  selectedRowsCount,
  totalCount,
}: MassActionsProps) => {
  return (
    <div className={Styles.massActionsSwitchFrame}>
      {massActionsMode ? (
        <>
          All {totalCount.toLocaleString('en-US')} rows are selected.
          <a className={Styles.massActionsSwitch} onClick={unselectAllRows}>
            Clear selection
          </a>
          .
        </>
      ) : (
        <>
          {selectedRowsCount} rows on this page are selected.
          <a onClick={activateMassActions} className={Styles.massActionsSwitch}>
            Select all {totalCount.toLocaleString('en-US')} rows
          </a>
          .
        </>
      )}
    </div>
  );
};
