import {ProfilesBffColumns} from '../../defs';

export const MEMBER_CONNECTIONS_COLUMNS_PREFERENCES_KEY = 'member_connections_columns_0'; // postfix in the name to abandon old, not-compatible settings before columns naming changes
export const availableColumns = [
  ProfilesBffColumns.FullName,
  ProfilesBffColumns.ConnectionsStrength,
  ProfilesBffColumns.JobTitle,
  ProfilesBffColumns.JobCompanyName,
  ProfilesBffColumns.JobCompanyWebsite,
  ProfilesBffColumns.LocationName,
  ProfilesBffColumns.Tags,
  ProfilesBffColumns.Emails,
];
