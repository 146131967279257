import {useParams} from 'react-router-dom';
import {useGetMember} from '../../api';
import {LoadingSpinner} from '../../components/animations';
import {NoResults} from '../../components/no-results';
import {useCurrentTeam} from '../../hooks';
import {MemberConnections} from './MemberConnections';

export const MemberConnectionsPage = () => {
  const {id: memberId = ''} = useParams();
  const {id: teamId} = useCurrentTeam();

  const {data: member, isLoading} = useGetMember(teamId, memberId);

  return isLoading ? (
    <LoadingSpinner />
  ) : !!member ? (
    <MemberConnections member={member.data.user} />
  ) : (
    <NoResults />
  );
};
